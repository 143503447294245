body {
  background-color: #6c0eff;
  background-image: url('./head-space/images/space_parallax.png');
  transition: background-image $mari;
  background-size: 1023px 397px !important;
  animation: space-parallax 32s infinite linear;
  image-rendering: pixelated;
}

body.lighter {
  background-color: #6c0eff;
  background-image: url('./head-space/images/space_parallax.png');
}

body * {
  image-rendering: auto;
}

@keyframes space-parallax {
  0% {
    background-position-x: 1023px;
  }

  100% {
    background-position-x: 0;
  }
}

.column {
  > .scrollable {
    background: $omori-background;
    backdrop-filter: $omori-backdrop-filter;
  }
}

.column-header__wrapper {
  background: $omori-background;
  backdrop-filter: $omori-backdrop-filter;
}

.column-back-button {
  background: $omori-background;
  backdrop-filter: $omori-backdrop-filter;
}

.tabs-bar__wrapper {
  background: $omori-background;
  backdrop-filter: $omori-backdrop-filter;
}

.search__input {
  background: $omori-background;
  backdrop-filter: $omori-backdrop-filter;
}

.navigation-bar {
  backdrop-filter: $omori-backdrop-filter;
}

.empty-column-indicator {
  background: $omori-background;
}

.notification__filter-bar {
  backdrop-filter: $omori-backdrop-filter;
}

.notification__filter-bar,
.account__section-headline {
  background: transparent;
}

.link-footer {
  backdrop-filter: $omori-backdrop-filter;
}

.ui__header {
  background: $omori-background !important;
  backdrop-filter: $omori-backdrop-filter;
}

.navigation-panel {
  background: $omori-background !important;
  backdrop-filter: $omori-backdrop-filter;
}

.button {
  color: $black;
}

.column-link {
  background: $omori-background;
}

.getting-started__wrapper {
  background: $omori-background;
}

.column-header__button {
  background: $omori-background;
}

.compose-form {
  .compose-form__warning,
  .reply-indicator,
  .autosuggest-textarea__textarea,
  .spoiler-input__input,
  .autosuggest-textarea__suggestions,
  .compose-form__modifiers,
  .compose-form__buttons-wrapper {
    color: $white;
    background: $black;
  }

  .reply-indicator__display-name,
  .reply-indicator__content {
    color: $white;
  }

  .autosuggest-textarea__suggestions__item {
    &:hover,
    &:focus,
    &:active,
    &.selected {
      background: lighten($black, 10%);
    }
  }

  .autosuggest-input {
    // border: 2px solid $black;
    border-radius: 0;

    .spoiler-input__input {
      // border: 4px solid $white;
      border-radius: 0;
    }
  }

  .spoiler-input.spoiler-input--visible {
    margin-bottom: 16px;
  }

  .compose-form__highlightable {
    // border: 2px solid $black;
    border-radius: 0;

    .compose-form__autosuggest-wrapper {
      // border: 4px solid $white;
      // border-bottom: none;
      border-radius: 0;
    }

    .compose-form__buttons-wrapper {
      // border: 4px solid $white;
      // border-top: none;
      border-radius: 0;
    }
  }
}

.privacy-dropdown__option {
  &:hover,
  &.active {
    color: $ui-box-color;

    .privacy-dropdown__option__content {
      color: $ui-box-color;

      strong {
        color: $ui-box-color;
      }
    }
  }
}

.privacy-dropdown__option__content {
  color: $ui-box-lighter-color;

  strong {
    color: $ui-box-lighter-color;
  }
}

// .compose-form__poll-wrapper .button.button-secondary,
// .compose-form .autosuggest-textarea__textarea::placeholder,
// .compose-form .spoiler-input__input::placeholder,
// .report-dialog-modal__textarea::placeholder,
.language-dropdown__dropdown__results__item__common-name {
  // .compose-form .icon-button {
  color: $ui-box-color;
}

.language-dropdown__dropdown__results__item.active {
  color: $ui-box-color;

  .language-dropdown__dropdown__results__item__common-name {
    color: $ui-box-color;
  }
}

.search-popout {
  color: $black;

  h4,
  li,
  ul,
  em {
    color: $black;
  }

  em {
    font-weight: bold;
  }
}

.search__popout {
  background-color: black;
}

.simple_form {
  select {
    border: 2px solid $white;
    border-radius: 0;

    option {
      color: $black;
    }
  }
}

.simple_form input[type='text'],
.simple_form input[type='number'],
.simple_form input[type='email'],
.simple_form input[type='password'],
.simple_form input[type='url'],
.simple_form input[type='datetime-local'],
.simple_form textarea {
  border: 2px solid $white;
  border-radius: 0;
}

.account__avatar {
  img {
    transition: opacity $mari;
  }
}

.drawer__header {
  backdrop-filter: $omori-backdrop-filter;
}

.drawer__inner__mastodon {
  background-image: none;
  transition: background-image $mari;
  background-size: contain;

  img {
    transition: opacity $mari;
  }
}

.status {
  border-bottom: 1px solid #fff8;
}

html {
  scrollbar-color: black white;
}

.rules-list {
  li {
    border-bottom: 1px solid #fff8;
  }
}

.account {
  border-bottom: 1px solid #fff8;
}

.react-toggle-track {
  background-color: $black;
}

.simple_form {
  .hint {
    code {
      background: #0008;
    }
  }

  input[type='text'],
  input[type='number'],
  input[type='email'],
  input[type='password'],
  input[type='url'],
  input[type='datetime-local'],
  textarea {
    border: 1px solid #fff8;

    &:hover {
      border-color: #fff;
    }
  }

  select {
    border: 1px solid #fff8;
  }
}

.container-alt {
  backdrop-filter: $omori-backdrop-filter;
}

.rules-list li::before {
  color: $black;
}

.search-results {
  backdrop-filter: $omori-backdrop-filter;
  background-color: rgba(0, 0, 0, 50%);
}

.modal-root__modal.list-adder {
  background-color: black;
}

/* April fools */

body.april-fools,
body.april-fools-force {
  background-color: #fda6e7;
  background-image: url('./head-space/images/sweet_parallax.png');
  transition: background-image $mari;

  .account__avatar {
    img {
      content: url('./head-space/images/sweetheart_ohohoho.png');
    }
  }

  .drawer__inner__mastodon {
    background-image: url('./head-space/images/sweetheart_mascot.png');
    transition: background-image $mari;

    img {
      opacity: 0;
      transition: opacity $mari;
    }
  }

  .announcements {
    img.announcements__mastodon {
      content: url('./head-space/images/sweetheart_mascot.png');
    }
  }
}

body.admin {
  background: url('./head-space/images/space_parallax.png');

  .content-wrapper {
    backdrop-filter: $omori-backdrop-filter;
  }

  .sidebar > ul {
    backdrop-filter: $omori-backdrop-filter;
  }
}
